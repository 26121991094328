<template>
    <div class="right_content">
        <div class="header">
            <el-tabs v-model="activeName" class="demo-tabs" @tab-change="handleClick">
                <el-tab-pane label="模型" :name="Constant.myCollection.model"></el-tab-pane>
                <el-tab-pane label="材质" :name="Constant.myCollection.material"></el-tab-pane>
            </el-tabs>
            <div style="margin-bottom: 20px;margin-top: 5px;">
                <BlockBtn text="取消收藏" @click="showDel"></BlockBtn>
            </div>
            <div style="margin-bottom: 10px;">
                <el-table :data="tableData" style="width: 100%" :border="false"
                    :header-cell-style="{ 'background': '#45485F' }" :row-class-name="'twoRow'"
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55" />
                    <el-table-column label="素材">
                        <template #default="{ row }">
                            <img :src="row.imgUrl" alt="" style="width: 100px;">

                        </template>
                    </el-table-column>
                    <el-table-column prop="categoryName" label="分类" />
                    <el-table-column prop="collectTime" label="收藏时间" />
                    <el-table-column label="尺寸">
                        <template #default="{ row }">
                            {{ row.length }} *
                            {{ row.width }} *
                            {{ row.height }}
                        </template>
                    </el-table-column>
                    <el-table-column label="品牌系列" >
                        <template #default="{ row }">
                            ———
                        </template>
                    </el-table-column>
                    <el-table-column label="销售价格" >
                        <template #default="{ row }">
                            ———
                        </template>
                    </el-table-column>
                    <el-table-column label="购买链接" >
                        <template #default="{ row }">
                            ———
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pageBox" style="padding-bottom: 10px;">
                <page :current-page="query.page" :page-size="query.size" :total-items="total"
                    @current="handleCurrentChange" @size="handleSizeChange" />
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    cancelCollection,
    myCollectionList,
} from '@/api/myCollection.js';
import page from '@/components/common/page';
import BlockBtn from '@/components/common/blockBtn';
import Constant from '@/constant/constant';
import { ref, onMounted } from 'vue'
import messageBox from '@/constant/message';

const activeName = ref(Constant.myCollection.model)
const tableData = ref([])
const query = ref({
    page: Constant.PaginationDefaultPage,
    size: Constant.PaginationDefaultSize,
})
const total = ref(0)
const selectRows = ref([])
function handleClick() {
    getDataList();
}

function handleCurrentChange(val) {
    query.value.page = val
    getDataList()
}
function handleSizeChange(val) {
    query.value.size = val
    getDataList()
}
async function getDataList() {
    try {
        const res = await myCollectionList({
            page: query.value.page,
            size: query.value.size,
            collectType: activeName.value
        })
        total.value = res.total;
        if (!res.result) {
            tableData.value = [];
            return;
        }
        res.result.forEach((item) => {
            item.imgUrl = `${process.env.VUE_APP_OSSURL}/${item.preview}`;
        })
        tableData.value = res.result;
    } catch (error) {
        console.log(error);
    }
}
function handleSelectionChange(val) {
    selectRows.value = val;
}
function showDel() {
    if (selectRows.value.length === 0) {
        messageBox.error('请选择素材!');
        return
    }
    messageBox.elMessageBox('确定取消收藏吗？').then(async () => {
        try {
            const delKey = activeName.value === 2 ? 'modelIds' : 'textureIds'
            const ids = selectRows.value.map((item) => item.collectId)
            await cancelCollection({ [delKey]: ids })
            getDataList()
        } catch (error) {
            messageBox.error('操作失败！');

        }
        messageBox.success('取消收藏成功!');

    }).catch((err) => {
        messageBox.info('已取消操作!');
    });
}
onMounted(() => {
    getDataList()
})
</script>

<style lang="scss" scoped>
.right_content {
    padding-top: 0;
    padding-bottom: 0;
    text-align: left;
    min-height: calc(100% - 68px);
    display: flex;
    flex-direction: column;

    .header {
        width: 100%;
        padding-top: 10px;
    }

    .infoBox {
        width: 100%;
    }

    .all {
        :hover {
            color: #923330;
        }
    }

    .custom-check {
        ::v-deep .el-checkbox__inner {
            background-color: #383B48 !important;
        }

        ::v-deep .el-checkbox__label {
            color: #fdfdfd;
        }

        :hover {
            color: #923330;
        }
    }
}
</style>