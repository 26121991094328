<template>
    <div class="sidebar_box">
        <div class="slider-box-t" :style="{ height: sliderTopHeight + 'px' }">
            <el-menu :default-active="route.path" class="el-menu-vertical-demo" background-color="#161824"
                text-color="#686870" active-text-color="#FDFDFD">
                <router-link to="/p">
                    <el-menu-item index="/p">
                        <template #title>
                            <Icon :iconObj="{
                                icon: require(`@/assets/leftBar/HomeFilled2.png`),
                                icon2: require(`@/assets/leftBar/HomeFilled1.png`),
                                checked: activeMenu == '/p'
                            }" :styleObject="{ 'height': '18px', 'width': '18px', 'margin-right': '10px', }" />
                            <span class="fontSize">主页</span>
                        </template>
                    </el-menu-item>
                </router-link>
                <div class="menuTit" :class="{ activeMenuTit: activeMenu.indexOf('/p/project') != -1 }">方案</div>
                <router-link to="/p/project/ps">
                    <el-menu-item index="/p/project/ps">
                        <template #title>
                            <Icon :iconObj="{
                                icon: require(`@/assets/leftBar/plan2.png`),
                                icon2: require(`@/assets/leftBar/plan1.png`),
                                checked: activeMenu == '/p/project/ps'
                            }" :styleObject="{ 'height': '18px', 'width': '18px', 'margin-right': '10px', }" />
                            <span class="fontSize">我的方案</span>
                        </template>
                    </el-menu-item>
                </router-link>
                <div class="menuTit" :class="{ activeMenuTit: activeMenu.indexOf('/p/material') != -1 }">素材</div>
                <router-link to="/p/material/me">
                    <el-menu-item index="/p/material/me">
                        <template #title>
                            <Icon :iconObj="{
                                icon: require(`@/assets/leftBar/texture2.png`),
                                icon2: require(`@/assets/leftBar/texture1.png`),
                                checked: activeMenu == '/p/material/me'
                            }" :styleObject="{ 'height': '18px', 'width': '18px', 'margin-right': '10px', }" />
                            <span class="fontSize">我的素材</span>
                        </template>
                    </el-menu-item>
                </router-link>
                <router-link to="/p/material/clt">
                    <el-menu-item index="/p/material/clt">
                        <template #title>
                            <Icon :iconObj="{
                                icon: require(`@/assets/leftBar/collet2.png`),
                                icon2: require(`@/assets/leftBar/collet1.png`),
                                checked: activeMenu == '/p/material/clt'
                            }" :styleObject="{ 'height': '18px', 'width': '18px', 'margin-right': '10px', }" />
                            <span class="fontSize">我的收藏</span>
                        </template>
                    </el-menu-item>
                </router-link>
                <!-- <router-link to="">
                <el-menu-item index="2">
                    <template #title>
                        <el-icon><Star /></el-icon>
                        <span>我的收藏</span>
                    </template>
                </el-menu-item>
            </router-link> -->
                <div class="menuTit"
                    :class="{ activeMenuTit: activeMenu == '/p/open' || activeMenu == '1' || activeMenu == '2' }">应用
                </div>
                <router-link to="/p/open">
                    <el-menu-item index="/p/open">
                        <template #title>
                            <Icon :iconObj="{
                                icon: require(`@/assets/leftBar/upload2.png`),
                                icon2: require(`@/assets/leftBar/upload1.png`),
                                checked: activeMenu == '/p/open'
                            }" :styleObject="{ 'height': '18px', 'width': '18px', 'margin-right': '10px', }" />
                            <span class="fontSize">开放素材</span>
                        </template>
                    </el-menu-item>
                </router-link>
                <router-link to="">
                    <el-menu-item index="1">
                        <template #title>
                            <Icon :iconObj="{
                                icon: require(`@/assets/leftBar/ai2.png`),
                                icon2: require(`@/assets/leftBar/ai1.png`),
                                checked: activeMenu == '1'
                            }" :styleObject="{ 'height': '18px', 'width': '18px', 'margin-right': '10px', }" />
                            <span class="fontSize" style="width: 100%" @click="handleToAI">图布斯AI</span>
                        </template>
                    </el-menu-item>
                </router-link>
                <router-link to="">
                    <el-menu-item index="2">
                        <template #title>
                            <Icon :iconObj="{
                                icon: require(`@/assets/leftBar/help2.png`),
                                icon2: require(`@/assets/leftBar/help1.png`),
                                checked: activeMenu == '2'
                            }" :styleObject="{ 'height': '18px', 'width': '18px', 'margin-right': '10px', }" />
                            <span class="fontSize" style="width: 100%" @click="handleToHelp">帮助中心</span>
                        </template>
                    </el-menu-item>
                </router-link>
                <div class="menuTit" :class="{ activeMenuTit: activeMenu == '/p/m/sub' || activeMenu == '/p/m/dir' }"
                    v-if="UserPerm.hasPerm(Constant.PermV2ComCenter)">商家中心</div>
                <router-link to="/p/m/sub" v-if="UserPerm.hasPerm(Constant.PermV2ComCenterSubAccount)">
                    <el-menu-item index="/p/m/sub">
                        <template #title>
                            <Icon :iconObj="{
                                icon: require(`@/assets/leftBar/plan2.png`),
                                icon2: require(`@/assets/leftBar/plan1.png`),
                                checked: activeMenu == '/p/m/sub'
                            }" :styleObject="{ 'height': '18px', 'width': '18px', 'margin-right': '10px', }" />
                            <span class="fontSize">子账户</span>
                        </template>
                    </el-menu-item>
                </router-link>
                <router-link to="/p/m/dir" v-if="UserPerm.hasPerm(Constant.PermV2ComCenterDir)">
                    <el-menu-item index="/p/m/dir">
                        <template #title>
                            <Icon :iconObj="{
                                icon: require(`@/assets/leftBar/plan2.png`),
                                icon2: require(`@/assets/leftBar/plan1.png`),
                                checked: activeMenu == '/p/m/dir'
                            }" :styleObject="{ 'height': '18px', 'width': '18px', 'margin-right': '10px', }" />
                            <span class="fontSize">素材目录</span>
                        </template>
                    </el-menu-item>
                </router-link>
                <!-- <router-link to="">
			<el-menu-item>
			<template #title>
				<el-icon><List /></el-icon>
				<span style="width: 100%;" @click="handleMission">任务大厅</span>
			</template>
			</el-menu-item>
		</router-link> -->
                <div class="menuTit" v-if="showPz" :class="{ activeMenuTit: activeMenu.substring(0, 2) == '/m' }">配置
                </div>

                <span v-for="(item, index) in pathList" :key="index">
                    <!-- 不存在children -->
                    <el-sub-menu :index="item.path" v-if="UserPerm.hasPerm(item.code)">
                        <template #title>
                            <el-icon>
                                <component :is="iconName(item.code)"></component>
                            </el-icon>
                            <span class="fontSize">{{ item.label }}</span>
                        </template>
                        <span v-for="ele in item.children" :key="ele.path">
                            <template v-if="ele.children?.length">
                                <el-sub-menu :index="ele.path" v-if="UserPerm.hasPerm(ele.meta.code)">
                                    <template #title>
                                        <span class="fontSize">{{ ele.meta.title }}</span>
                                    </template>
                                    <span v-for="el in ele.children" :key="el.path">
                                        <router-link :to="'/' + item.path + '/' + ele.path + '/' + el.path"
                                            :key="el.meta.title" v-if="
                                                UserPerm.hasPerm(el.meta.code) &&
                                                el.meta.show
                                            ">
                                            <el-menu-item :index="'/' + item.path + '/' + ele.path + '/' + el.path">
                                                <span class="fontSize">{{ el.meta.title }}</span>
                                            </el-menu-item>
                                        </router-link>
                                    </span>
                                </el-sub-menu>
                            </template>
                            <template v-else>
                                <router-link :to="'/' + item.path + '/' + ele.path" :key="ele.meta.title" v-if="
                                    UserPerm.hasPerm(ele.meta.code) &&
                                    ele.meta.show
                                ">
                                    <el-menu-item :index="'/' + item.path + '/' + ele.path">
                                        <span class="fontSize">{{ ele.meta.title }}</span>
                                    </el-menu-item>
                                </router-link>
                            </template>
                        </span>
                    </el-sub-menu>
                </span>
            </el-menu>
        </div>
        <div class="slider-box-b">
            <el-menu :default-active="route.path" class="el-menu-vertical-demo" background-color="#161824"
                text-color="#686870" active-text-color="#FDFDFD">
                <router-link to="/p/recovery">
                    <el-menu-item index="/p/recovery">
                        <template #title>
                            <Icon :iconObj="{
                                icon: require(`@/assets/leftBar/garbage2.png`),
                                icon2: require(`@/assets/leftBar/garbage1.png`),
                                checked: activeMenu == '/p/recovery'
                            }" :styleObject="{ 'height': '18px', 'width': '18px', 'margin-right': '10px', }" />
                            <span class="fontSize">回收站</span>
                        </template>
                    </el-menu-item>
                </router-link>
            </el-menu>
        </div>
    </div>
</template>

<script setup>
import Icon from '@/components/common/icon';
import { getTreeApi } from '@/api/login';
import Constant from "@/constant/constant";
import UserPerm from "@/constant/userPerm";
import { useRouter, useRoute } from "vue-router";
import { ref, computed, onMounted } from "vue";
// 获取后台管理页面路由
const showPz = ref(false)
const router = useRouter().options?.routes?.find(
    (item) => item.name === "manage"
)?.children;
const route = useRoute();
const pathList = ref([]);
const menuList = ref([]);
menuList.value = Constant.MenuList();
const nodeId = ref(0)
const sliderTopHeight = ref(0)
getTreeApi({ bizCode: Constant.TreeArcticle, nodeId: '0', }).then((resp) => {
    nodeId.value = resp.result.children[0].nodeId
}).catch((err) => {
});
const activeMenu = computed(() => route.path)
// 适配渲染数据
router?.map((res) => {
    if (res.path === "/" || res.path === "/login") {
        return;
    }
    if (!res.meta.show) return
    if (res.children?.length > 0) {
        pathList.value.push({
            label: res.meta.title,
            path: "m/" + res.path,
            code: res.meta.code,
            children: res.children,
        });
    } else {
        pathList.value.push({
            label: res.meta.title,
            path: "m/" + res.path,
            code: res.meta.code,
            children: [],
        });
    }
});
pathList.value.filter(res => {
    if (UserPerm.hasPerm(res.code)) {
        showPz.value = true
    }
})
//function
const iconName = (code) => {
    let icon = "";
    menuList.value.forEach((item) => {
        if (code === item.code) {
            icon = item.icon;
        }
    });
    return icon;
};

const handleMission = () => {
    window.open(process.env.VUE_APP_MISSION_DOMAIN, '_blank')
};
const handleToAI = () => {
    window.open(process.env.VUE_APP_AI_DOMAIN, '_blank')
};
const handleToHelp = () => {
    window.open(process.env.VUE_APP_HELP_DOMAIN + '/help/' + nodeId.value, '_blank')
}
onMounted(() => {
    sliderTopHeight.value = window.innerHeight - 120
})
</script>
<style lang="scss"></style>
<style lang="scss" scoped>
.fontSize {
    font-size: 17px;
}

.imgIcon {
    height: 18px;
    width: 18px;
    margin-right: 10px;
}

.el-menu {
    border-right: none;
}

a {
    text-decoration: none !important;
}

.sidebar_box {
    left: 0;
    width: var(--sideWidth);
    overflow-y: auto;
    position: absolute;
    height: calc(100% - var(--headHeight));
    top: var(--headHeight);
    background: #161824;
    box-shadow: 0px 6px 22px 0px rgba(0, 0, 0, 1);
    position: fixed;
    height: calc(100% - 60px);
    overflow: auto;

    .slider-box-t {
        overflow: auto;

        .menuTit {
            font-size: 12px;
            margin-left: 12px;
            color: #686870;
        }

        .activeMenuTit {
            color: #fdfdfd;
        }
    }

}
</style>
