<template>
	<div class="home">
		<nav-header style="z-index:10;"></nav-header>
		<side-bar style="z-index:11;"></side-bar>

		<div>
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
import NavHeader from '../components/NavHeader';
import SideBar from '../components/SiderBar';
import { OSSURL } from '@/util/file';

export default {
	components: {
		NavHeader,
		SideBar,
	},
	setup() {
		return {
			OSSURL
		}
	},
}
</script>
<style lang="scss">
.home {
	background: #161824;
	position: relative;
	width: 100%;
	height: 100%;
	font-family: 'PingFang SC', 'Helvetica', 'Source Han Sans';
}
.affix-service {
	position: fixed;
	bottom: 100px;
	right: 50px;
	width: 70px;
	height: 70px;
	// font-size: 12px;
	text-align: center;
	box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, .12);
	border-radius: 8px;
	color: #FEFDFD;
	background: #383B48;
	cursor: pointer;

	&:hover {
		color: #fff;
		background: $--el-color-primary;
	}

	>div {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 4px;

		>p {
			height: 20px;
			line-height: 20px;
		}
	}
}

.right_content {
	color: #fdfdfd;
	position: absolute;
	left: calc(var(--sideWidth) + 8px);
	width: calc(100% - var(--sideWidth) - 40px);
	height: calc(100% - var(--headHeight) - 88px);
	top: calc(var(--headHeight) + 8px);
	background: #161824;
	padding: 20px;
	padding-left: 12px;
	overflow-y: auto;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

	.top {
		width: calc(100% - 40px);
	}
}

.smallLabel {
	transform: scale(0.83);
	float: right;
	width: calc(100% - 226px);
	margin-top: var(--headHeight);
}

.blank_box {
	position: relative;
	width: 100%;
	height: auto;
	flex-grow: 1;
	background-position-x: center !important;
	background-position-y: 40% !important;
}

.affix-service {
	position: fixed;
	bottom: 100px;
	right: 50px;
	width: 80px;
	height: 80px;
	font-size: 12px;
	line-height: 80px;
	text-align: center;
	box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, .12);
	background-color: #fff;
	border-radius: 8px;
	color: $--el-color-primary;
	cursor: pointer;
	z-index: 4;

	&:hover {
		color: #fff;
		background: $--el-color-primary;
	}

	>div {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 4px;

		>p {
			height: 20px;
			line-height: 20px;
			margin: 0;
		}
	}
}
</style>
