import { postAction, getAction } from "./manage";
class Api {
  // 生成完整url
  genV1ApiUrl(path) {
    return `v1/api/${path}`;
  }
  /**
   * 方案列表点赞
   *
   * @param {Object} data 请求数据对象
   * @returns {Promise<any>} 异步请求的结果
   */
  likeProgramme(data) {
    return postAction(this.genV1ApiUrl("ctb/like"), data);
  }
  /**
   * 删除方案审核数据
   *
   * @param {Object} data - 包含要删除的审核记录信息的对象
   * @returns {Promise<any>} 返回一个Promise对象，表示异步操作的结果
   */
  delProgrammeAudit(data) {
    return postAction(this.genV1ApiUrl("ctb/state"), data);
  }
  /**
   * 投稿编辑时查看详情
   *
   * @param data 请求数据
   * @returns 返回 postAction 的结果
   */
  editProgrammeDetail(data) {
    return postAction(this.genV1ApiUrl("ctb/view"), data);
  }
  /**
   * 打开方案
   *
   * @param data 请求数据
   * @returns 返回 postAction 的结果
   */
  openProgramme(data) {
    return postAction(this.genV1ApiUrl("ctb/open"), data);
  }
  /**
   * 用户详情

   *
   * @param {Object} data - 请求数据
   * @returns {Promise} - 返回一个 Promise 对象，包含请求结果
   */
  openUserView(data) {
    return postAction(this.genV1ApiUrl("user/public/view"), data);
  }
  /**
   * 模板详情
   *
   * @param data 请求数据对象
   * @returns 返回请求结果
   */
  productDetail(data) {
    return postAction(this.genV1ApiUrl("ctb/pub/dtl"), data);
  }
  /**
   * 模板中心列表
   *
   * @param {Object} data 请求的数据
   * @returns {Promise<any>} 返回产品列表的Promise对象
   */
  productList(data) {
    return postAction(this.genV1ApiUrl("ctb/pub/list"), data);
  }
  /**
   * 方案审核的审核记录
   *
   * @param data 日志数据对象
   * @returns 返回发送请求的结果
   */
  planRecordList(data) {
    return postAction(this.genV1ApiUrl("ctb/prove/log"), data);
  }
  /**
   * 方案审核操作
   *
   * @param {Object} data - 包含计划操作所需参数的对象
   * @returns {Promise<Object>} 返回执行结果的Promise对象
   */
  planOperation(data) {
    return postAction(this.genV1ApiUrl("ctb/prove"), data);
  }
  /**
   * 方案审核列表
   *
   * @param data 请求的数据
   * @returns 返回 postAction 函数执行的结果
   */
  planReviewList(data) {
    return postAction(this.genV1ApiUrl("ctb/prove/list"), data);
  }
  //售卖方案列表
  sellRecordList() {
    return postAction(this.genV1ApiUrl("ctb/sell/list"));
  }
  //方案渲染图
  programmeImageList(data) {
    return postAction(this.genV1ApiUrl("project/render/image"), data);
  }
  //选择方案
  programmeList(data) {
    return postAction(this.genV1ApiUrl("ctb/filter"), data);
  }
  //提交投稿方案
  saveScript(data) {
    return postAction(this.genV1ApiUrl("ctb/save"), data);
  }
  // 批量删除材质
  TextureBatchDel(data) {
    return postAction(this.genV1ApiUrl("texture/batch/del"), data);
  }
  // 批量删除模型
  ModelBatchDel(data) {
    return postAction(this.genV1ApiUrl("model/batch/del"), data);
  }
  // 前端oss直传获取秘钥
  UploadCredential(data) {
    return postAction(this.genV1ApiUrl("upload/credential"), data);
  }
  // 常量
  CondObject() {
    return getAction(this.genV1ApiUrl("cond/object"));
  }
  // 生成验证码
  Captcha() {
    return postAction(this.genV1ApiUrl("captcha"), {});
  }
  // 商家列表
  CompanyList(data) {
    return postAction(this.genV1ApiUrl("company/list"), data);
  }
  // 商家保存
  CompanySave(data) {
    return postAction(this.genV1ApiUrl("company/save"), data);
  }
  // 商家状态修改
  CompanyState(data) {
    return postAction(this.genV1ApiUrl("company/state"), data);
  }
  // 商家详情
  CompanyView(data) {
    return postAction(this.genV1ApiUrl("company/view"), data);
  }
  // 根据code查询字典列表
  DictCodeList(data) {
    return postAction(this.genV1ApiUrl("dict/code-list"), data);
  }
  // 字典列表
  DictList(data) {
    return postAction(this.genV1ApiUrl("dict/list"), data);
  }
  // 字典保存
  DictSave(data) {
    return postAction(this.genV1ApiUrl("dict/save"), data);
  }
  // 字典状态修改
  DictState(data) {
    return postAction(this.genV1ApiUrl("dict/state"), data);
  }
  // 字典详情
  DictView(data) {
    return postAction(this.genV1ApiUrl("dict/view"), data);
  }
  // 根据类型查询标签列表
  TagTypeList(data) {
    return postAction(this.genV1ApiUrl("tag/type-list"), data);
  }
  // 标签列表
  TagList(data) {
    return postAction(this.genV1ApiUrl("tag/list"), data);
  }
  // 标签保存
  TagSave(data) {
    return postAction(this.genV1ApiUrl("tag/save"), data);
  }
  // 标签状态修改
  TagState(data) {
    return postAction(this.genV1ApiUrl("tag/state"), data);
  }
  // 标签详情
  TagView(data) {
    return postAction(this.genV1ApiUrl("tag/info"), data);
  }
  // 角色用户组保存
  RoleGroupSave(data) {
    return postAction(this.genV1ApiUrl("role-group/save"), data);
  }
  // 角色用户组查看
  RoleGroupView(data) {
    return postAction(this.genV1ApiUrl("role-group/view"), data);
  }
  // 角色列表
  RoleList(data) {
    return postAction(this.genV1ApiUrl("role/list"), data);
  }
  // 角色保存
  RoleSave(data) {
    return postAction(this.genV1ApiUrl("role/save"), data);
  }
  // 角色状态修改
  RoleState(data) {
    return postAction(this.genV1ApiUrl("role/state"), data);
  }
  // 角色查看
  RoleView(data) {
    return postAction(this.genV1ApiUrl("role/view"), data);
  }
  // 发送短信验证码
  SendSms(data) {
    return postAction(this.genV1ApiUrl("send/sms"), data);
  }
  // 查看本节点信息
  TreeInfo(data) {
    return postAction(this.genV1ApiUrl("tree/info"), data);
  }
  // 树形节点保存
  TreeSave(data) {
    return postAction(this.genV1ApiUrl("tree/save"), data);
  }
  // 树形节点列表
  TreeList(data) {
    return postAction(this.genV1ApiUrl("tree/list"), data);
  }
  // 查看本节点下的树形结构
  TreeView(data) {
    return postAction(this.genV1ApiUrl("tree/view"), data);
  }
  // 账户列表
  UserList(data) {
    return postAction(this.genV1ApiUrl("user/list"), data);
  }
  // 账户登录
  UserLogin(data) {
    return postAction(this.genV1ApiUrl("user/login"), data);
  }
  // 账户登录状态监测
  UserLoginCheck(data) {
    return postAction(this.genV1ApiUrl("user/login-check"), data);
  }
  // 获取当前登录用户的权限
  UserPerm(data) {
    return postAction(this.genV1ApiUrl("user/perm"), data);
  }
  // 获取账户权益
  bfUser(data) {
    return postAction(this.genV1ApiUrl("bf/user"), data);
  }
  // 根据手机号查询用户
  UserSearchPhone(data) {
    return postAction(this.genV1ApiUrl("user/phone"), data);
  }
  // 用户注册
  UserRegister(data) {
    return postAction(this.genV1ApiUrl("user/register"), data);
  }
  // 重置密码
  UserResetPassword(data) {
    return postAction(this.genV1ApiUrl("user/reset/password"), data);
  }
  // 账户保存
  UserSave(data) {
    return postAction(this.genV1ApiUrl("user/save"), data);
  }
  // 账户状态修改
  UserState(data) {
    return postAction(this.genV1ApiUrl("user/state"), data);
  }
  // 账户详情
  UserView(data) {
    return postAction(this.genV1ApiUrl("user/view"), data);
  }
  // 账户找回密码
  UserFindPass(data) {
    return postAction(this.genV1ApiUrl("user/find/password"), data);
  }
  // 前端oss直传获取秘钥
  UploadCredential(data) {
    return postAction(this.genV1ApiUrl("upload/credential"), data);
  }
  // 模型列表
  ModelList(data) {
    return postAction(this.genV1ApiUrl("model/page-list"), data);
  }
  // 模型上传
  ModelUpload(data) {
    return postAction(this.genV1ApiUrl("upload/model"), data);
  }
  // 模型状态修改
  ModelState(data) {
    return postAction(this.genV1ApiUrl("model/state"), data);
  }
  // 模型更新
  ModelUpdate(data) {
    return postAction(this.genV1ApiUrl("model/update"), data);
  }
  // 模型查看
  ModelView(data) {
    return postAction(this.genV1ApiUrl("model/view"), data);
  }
  // 材质列表
  TextureList(data) {
    return postAction(this.genV1ApiUrl("texture/page-list"), data);
  }
  // 材质上传
  TextureUpload(data) {
    return postAction(this.genV1ApiUrl("upload/texture"), data);
  }
  // 材质状态修改
  TextureState(data) {
    return postAction(this.genV1ApiUrl("texture/state"), data);
  }
  // 材质更新
  TextureUpdate(data) {
    return postAction(this.genV1ApiUrl("texture/update"), data);
  }
  // 材质查看
  TextureView(data) {
    return postAction(this.genV1ApiUrl("texture/view"), data);
  }
  // 目录列表
  FolderList(data) {
    return postAction(this.genV1ApiUrl("/folder/list"), data);
  }
  // 目录状态修改
  FolderState(data) {
    return postAction(this.genV1ApiUrl("folder/state"), data);
  }
  // 目录保存
  FolderSave(data) {
    return postAction(this.genV1ApiUrl("folder/save"), data);
  }
  // 目录查看
  FolderView(data) {
    return postAction(this.genV1ApiUrl("folder/view"), data);
  }
  // 目录列表
  DirList(data) {
    return postAction(this.genV1ApiUrl("material/dir/list"), data);
  }
  // 保存目录
  DirSave(data) {
    return postAction(this.genV1ApiUrl("material/dir/save"), data);
  }
  // 目录拖动排序
  DirSort(data) {
    return postAction(this.genV1ApiUrl("material/dir/sort"), data);
  }
  // 目录状态
  DirState(data) {
    return postAction(this.genV1ApiUrl("material/dir/state"), data);
  }
  // 目录详情
  DirView(data) {
    return postAction(this.genV1ApiUrl("material/dir/view"), data);
  }

  // 个人详情tips
  userTips(data) {
    return postAction(this.genV1ApiUrl("user/tips"), data);
  }
  // 方案复制
  ProjectCopy(data) {
    return postAction(this.genV1ApiUrl("project/copy"), data);
  }
  // 方案列表
  ProjectList(data) {
    return postAction(this.genV1ApiUrl("project/page-list"), data);
  }
  // 方案状态修改
  ProjectState(data) {
    return postAction(this.genV1ApiUrl("project/state"), data);
  }
  // 方案保存
  ProjectSave(data) {
    return postAction(this.genV1ApiUrl("project/save"), data);
  }
  // 方案修改
  ProjectUpdate(data) {
    return postAction(this.genV1ApiUrl("project/update"), data);
  }
  // 方案移动
  ProjectMove(data) {
    return postAction(this.genV1ApiUrl("project/move"), data);
  }
  // 方案批量删除移动
  ProjectOp(data) {
    return postAction(this.genV1ApiUrl("project/op"), data);
  }
  // 方案查看
  ProjectView(data) {
    return postAction(this.genV1ApiUrl("project/view"), data);
  }
  // 方案详情查看
  ProjectDetail(data) {
    return postAction(this.genV1ApiUrl("project/detail"), data);
  }
  // 方案渲染图
  ProjectRenderImage(data) {
    return postAction(this.genV1ApiUrl("project/render/image"), data);
  }
  // 验证码token
  CaptchaToken(data) {
    return postAction(this.genV1ApiUrl("captcha/token"), data);
  }
  // 发送短信验证码
  SendSms(data) {
    return postAction(this.genV1ApiUrl("send/sms"), data);
  }
  // 用户基本资料修改
  UserBasicSave(data) {
    return postAction(this.genV1ApiUrl("user/basic/save"), data);
  }
  // 账户名称修改
  UserNameSave(data) {
    return postAction(this.genV1ApiUrl("user/name/save"), data);
  }
  // 微信pc端回调
  WechatCallback(data) {
    return postAction(this.genV1ApiUrl("wechat/callback"), data);
  }
  // 微信绑定手机号
  WechatBind(data) {
    return postAction(this.genV1ApiUrl("wechat/bind"), data);
  }
  // 微信配置信息
  WechatSetting(data) {
    return postAction(this.genV1ApiUrl("wechat/setting"), data);
  }

  getDictFormCodeApi(data) {
    return postAction(this.genV1ApiUrl("dict/code-list"), data);
  }

  getAreaCascaderApi(data) {
    return postAction(this.genV1ApiUrl("area/list"), data);
  }
}
export default new Api();
