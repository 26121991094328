<template>
    <div class="editor-container clearfix">
        <div class="input-wrap">
            <div class="input-area" ref="editableInputAreaDiv" contenteditable="true" :placeholder="placeholder"></div>
            <ul class="images">
                <li v-for="(item, index) in images" :key="index">
                    <el-icon class="close" @click="delImg(index)">
                        <Close />
                    </el-icon>
                    <img :src="OSSURL + item" alt="" srcset="" @click="showBigImg(item)">
                </li>
            </ul>
        </div>
        <div class="input-area-bar">
            <div class="bar-left">
                <el-popover ref="emojiPopover" placement="top" :width="366" trigger="click">
                    <ul class="emoji-ul">
                        <li class="emoji-li" v-for="(emoji, key) in emojiList" :key="key">
                            <span class="emoji" @click="addEmoji(emoji)">
                                {{ emoji }}
                            </span>
                        </li>
                    </ul>
                    <template #reference>
                        <img src="@/assets/icons/smile.png"
                            style="margin-right: 10px;vertical-align: middle;cursor: pointer;width: 24px;height: 24px;"
                            alt="" srcset="">
                    </template>
                </el-popover>
                <el-upload style="height: 24px;" :show-file-list="false" action accept=".png,.jpg,.jpeg,.svg"
                    :http-request="file => handleUpload(file)">
                    <img src="@/assets/icons/picture.png" style="vertical-align: middle;width: 24px;height: 24px;"
                        alt="" srcset="">
                </el-upload>
            </div>
            <div class="bar-right">
                <div class="total-world" :class="{ overLimit: worldTotal > 200 }">{{ worldTotal }}/200</div>
                <el-button :disabled="areaContent.length === 0 || worldTotal > 200" type="danger"
                    style="background-color: #cf3861;" @click="publish">发布</el-button>
            </div>
        </div>
    </div>
    <div class="zoom-img" v-show="isZoom" @click="() => isZoom = false">
        <img :src="OSSURL + currImage">
    </div>
</template>
<script setup>
import { OSSURL, UploadFile, genID } from '@/util/file';
import { ref, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { isUserLoggedIn } from '@/router'
const props = defineProps({
    model: Object
})
const route = useRoute()
const store = useStore()
const emit = defineEmits(['submit'])
const editableInputAreaDiv = ref(null);
const lastEditRange = ref(null);
const emojiPopover = ref(null);
const emojiList = ref([
    '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '☺️', '😊', '😇', '🙂', '🙃', '😉',
    '😌', '😍', '🥰', '😘', '😗', '😙', '😚', '😋', '😛', '😝', '😜', '🤪', '🤨', '🧐',
    '🤓', '😎', '🥳', '😏', '😒', '😞', '😔', '😟', '😕', '🙁', '☹️', '😣', '😖', '😫',
    '😩', '🥺', '😢', '😭', '😤', '😠', '😡', '🤬', '🤯', '😳', '🥵', '🥶', '😱', '😨',
    '😰', '😥', '😓', '🤗', '🤔', '🤭', '🤫', '🤥', '😶', '😐', '😑', '😬', '🙄', '😯',
    '😦', '😧', '😮', '😲', '🥱', '😴', '🤤', '😪', '😵', '🤐', '🥴', '🤢', '🤮', '🤧',
    '😷', '🤒', '🤕', '🤑', '👋', '🤚', '🖐', '✋', '🖖', '👌', '🤏', '✌️', '🤞', '🤟',
    '🤘', '🤙', '👈', '👉', '👆', '🖕', '👇', '☝️', '👍', '👎', '✊', '👊', '🤛', '🤜',
    '👏', '🙌', '👐', '🤲', '🤝', '🙏', '✍️', '💅', '🤳', '💪', '💋', '❤️', '🧡', '💛',
    '💚', '💙', '💜', '🖤', '🤍', '🤎', '💔', '❣️', '💕', '💞', '💓', '💗', '💖', '💘',
    '💝', '💯', '🎄', '🌲', '🌳', '🌴'
]);
const images = ref([]);
const areaContent = ref('');
const isZoom = ref(false);
const currImage = ref('');
const worldTotal = ref(0);
const placeholder = ref('写下你的评论...');
watch(() => props.model.clear, (flag) => {
    if (flag) {
        editableInputAreaDiv.value.innerText = '';
        props.model.clear = false;
    }
})
/**
 * 初始化可编辑输入区域的 div 元素
 *
 * 为可编辑的 div 元素添加点击事件监听器，当点击该 div 时，会获取当前选定的文本范围，
 * 并将最后的光标位置保存到 lastEditRange 中，以便后续操作。
 */
function initLastEditRange() {
    // 获取选定对象
    let selection = window.getSelection()
    // 设置最后光标对象
    if (selection.rangeCount > 0) {
        // 记录光标最后点击可编辑div中所选择的位置
        lastEditRange.value = selection.getRangeAt(0);
    }
}
/**
 * 初始化可编辑输入框的div元素
 *
 * 为可编辑输入框的div元素绑定点击和按键抬起事件，以初始化最后一次编辑范围
 */
function initEditableInputAreaDiv() {
    editableInputAreaDiv.value.onclick = initLastEditRange;
    editableInputAreaDiv.value.onkeyup = initLastEditRange;
    editableInputAreaDiv.value.oninput = () => {        
        placeholder.value = '';
        if (editableInputAreaDiv.value.innerText.length === 0) {            
            editableInputAreaDiv.value.innerText = ''
        }
        worldTotal.value = editableInputAreaDiv.value.innerText.trim().length;
        areaContent.value = editableInputAreaDiv.value.innerText;
    }
    editableInputAreaDiv.value.onblur = () => {
        if (editableInputAreaDiv.value.innerText.trim() === '') {
            placeholder.value = '写下你的评论...'
        }
    }
}
/**
 * 在可编辑区域插入emoji表情。
 *
 * @param emoji 需要插入的emoji名称。
 */
function addEmoji(emoji) {
    emojiPopover.value.hide()
    placeholder.value = '';
    let edit = editableInputAreaDiv.value;
    edit.focus()
    let selection = window.getSelection()
    // 如果存在最后的光标对象
    if (lastEditRange.value) {
        // 选区对象清除所有光标
        selection.removeAllRanges();
        // 并添加最后记录的光标，以还原之前的状态
        selection.addRange(lastEditRange.value);
        // 获取到最后选择的位置
        var range = selection.getRangeAt(0);
        // 在此位置插入表情图        
        range.insertNode(document.createTextNode(emoji))
        // range.insertNode(image) // 或者这样插入
        // false，表示将Range对象所代表的区域的起点移动到终点处
        range.collapse(false)
        // 记录最后的位置
        lastEditRange.value = selection.getRangeAt(0);
    } else {
        // 创建一个img标签（表情）
        // 将表情添加到可编辑的div中，作为可编辑div的子节点
        edit.appendChild(document.createTextNode(emoji))
        // 使用选取对象，选取可编辑div中的所有子节点
        selection.selectAllChildren(edit)
        // 合并到最后面，即实现了添加一个表情后，把光标移到最后面
        selection.collapseToEnd()
    }
    areaContent.value = editableInputAreaDiv.value.innerText;
    worldTotal.value = areaContent.value.length;
}
function showBigImg(url) {
    currImage.value = url
    isZoom.value = true
}
/**
 * 处理文件上传的函数
 *
 * @param file 上传的文件对象
 * @returns 无返回值
 */
async function handleUpload(file) {
    try {
        if (!isUserLoggedIn()) {
            handleLogin();
            return;
        }
        const arr = file.file.name.split('.')
        const fileName = genID() + '.' + arr[arr.length - 1] //文件名
        const res = await UploadFile(fileName, file.file);
        images.value.push(res)
    } catch (error) {
        console.log(error);
    }
}

function delImg(index) {
    images.value.splice(index, 1)
}
/**
 * 提交评论
 *
 * 触发'submit'事件，并将editableInputAreaDiv元素的innerHTML内容作为参数传递
 */
function publish() {
    if (!isUserLoggedIn()) {
        handleLogin();
        return;
    }
    emit('submit', { contentHtml: editableInputAreaDiv.value.innerHTML, ...props.model, images: JSON.parse(JSON.stringify(images.value)), oldItem: props.model });
    editableInputAreaDiv.value.innerHTML = '';
    areaContent.value = '';
    images.value = [];
    worldTotal.value = 0;
}

async function handleLogin() {
    await store.dispatch('getWeChatSetting');
    store.commit('SET_LOGIN_MODAL_VISIBLE', {
        visible: true,
        type: 'wxLogin',
        path: route.fullPath,
    });
}

onMounted(() => {
    initEditableInputAreaDiv()
})
</script>

<style scoped lang="scss">
.el-button.is-disabled {
    background-color: #ccc !important;
}

.editor-container {
    margin: 10px auto;
    border-radius: 5px;
    border: 2px solid #fff;
    padding: 8px 12px;

    .input-wrap {
        >.images {
            display: flex;
            flex-wrap: wrap;

            >li {
                position: relative;
                width: 50px;
                height: 50px;
                padding: 16px;

                >.close {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    color: #fff;
                    cursor: pointer;
                }

                >img {
                    cursor: pointer;
                    height: 50px;
                    width: 50px;
                }
            }
        }

        >.input-area {
            margin-bottom: 10px;
            outline: none;
            font-size: 16px;
            overflow-y: scroll;
            color: #fff;

            ::v-deep img {
                width: 20px;
                height: 20px;
                vertical-align: bottom;
                margin: 0 2px;

            }
        }

        .input-area:before {
            cursor: text;
            content: attr(placeholder);
            font-size: 16px;
            color: #999;
        }

        .input-area:focus:before {
            content: "";
        }

    }


    .input-area-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 20px;
        height: 30px;

        .bar-left {
            display: flex;
            align-items: center;
        }

        .bar-right {
            display: flex;
            align-items: center;
            color: #999;

            >.total-world {
                cursor: default;
                margin-right: 10px;

                &.overLimit {
                    color: #cf3861;
                }
            }
        }
    }
}

.clearfix::before,
.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.12);
    background: rgba(0, 0, 0, 0.2);
}

/*::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        border-radius: 10px;
        background: rgba(0,0,0,0.1);
    }*/

.emoji-ul {
    display: flex;
    flex-wrap: wrap;
    max-height: 360px;
    overflow-y: auto;
    overflow-x: hidden;

    >.emoji-li {
        .emoji {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 20px;
            height: 20px;
            margin: 2px;
            padding: 9px;
            border-radius: 8px;
            transition: all .28s;
            font-size: 20px;

            &:hover {
                transform: scale(1.2);
            }

            &:hover {
                background-color: #eaeaea;
            }
        }
    }

}

.zoom-img {
    z-index: 999;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
}
</style>
