import { postAction } from "./manage";
const genV1ApiUrl = (path) => {
    return `v1/api/${path}`
}

export const addCommentApi = (params) => {
    return postAction(genV1ApiUrl('comment/add'), params)
}

export const expandCommentApi = (params) => {
    return postAction(genV1ApiUrl('comment/expand'), params)
}

export const likeCommentApi = (params) => {
    return postAction(genV1ApiUrl('comment/like'), params)
}

export const commentListApi = (params) => {
    return postAction(genV1ApiUrl('comment/list'), params)
}