<template>
    <div class="comment-wrap" :style="{ width: props.width }">
        <div class="header">
            <h1>评论区({{ commentData.total }})</h1>
            <Editor :model="editorModel" @submit="submitComment($event, 1)"></Editor>
        </div>
        <ul class="comments-ul">
            <li class="comment-li" v-for="item in commentData.list" :key="item.info.commentId">
                <div class="comment-avator">
                    <img :src="OSSURL + item.info.author.avatar" alt="" @click="goPersonal(item.info.author)">
                </div>
                <div class="comment-main" :style="handlercommentMain(props.width)">
                    <div class="auhtor-info">
                        <span class="author-name" @click="goPersonal(item.info.author)">{{ item.info.author.nickName
                            }}</span>
                    </div>
                    <div class="content">
                        {{ item.info.content }}
                    </div>
                    <ul class="img-list">
                        <li v-for="(item_, index) in item.attachList" :key="index">
                            <img :src="OSSURL + item_.filepath" alt="" @click="showBigImg(item_.filepath)">
                        </li>
                    </ul>
                    <div class="content-bottom">
                        <span class="create-time">{{ item.info.submitTime }}</span>
                        <div class="btns">
                            <el-button link v-show="!item.unfold" @click="switchUnfoldLevelOne(item)" class="grey">
                                <el-icon>
                                    <ChatLineRound />
                                </el-icon>
                                <span>回复</span>
                            </el-button>
                            <el-button link v-show="item.unfold" @click="switchUnfoldLevelOne(item)"
                                class="grey">收起∧</el-button>
                            <div class="like" @click="likeComment(item)">
                                <img src="@/assets/icons/heart-w.png" alt="" srcset=""
                                    style="width:16px;cursor: pointer;margin-right: 3px;"
                                    v-show="item.info.likeStatus !== 1">
                                <img src="@/assets/icons/heart-r.png" alt="" srcset=""
                                    style="width:16px;cursor: pointer;margin-right: 3px;"
                                    v-show="item.info.likeStatus === 1">
                                <span v-text="item.info.likeNum" class="grey"></span>
                            </div>
                        </div>
                    </div>
                    <div class="comment-editor" v-show="item.unfold">
                        <Editor :model="item" @submit="submitComment($event, 2)"></Editor>
                    </div>
                    <ul class="reply-ul" v-show="item.replyList.length">
                        <li class="reply-li" v-for="item_ in item.replyList" :key="item_.commentId">
                            <div class="reply-li-main">
                                <div class="reply-li-l">
                                    <div class="reply-avatar">
                                        <img :src="OSSURL + item_.author.avatar" alt=""
                                            @click="goPersonal(item_.author)">
                                    </div>
                                    <div class="main-top">
                                        <div class="reply-auhtor-info">
                                            <span class="author-name">
                                                <span class="self" @click="goPersonal(item_.author)">{{
                                                    item_.author.nickName
                                                    }}</span>
                                                <span v-if="item_.reply && !item_.isAuthor" style="color: #dbdbdb;">@{{
                                                    item_.reply.nickName }}</span>
                                                <span v-else-if="item_.isAuthor" style="color: #dbdbdb;">@我</span>
                                                <!-- @{{item_.reply.nickName  }} -->
                                            </span>
                                        </div>
                                        <div class="reply-content">
                                            {{ item_.content }}
                                        </div>
                                        <ul class="reply-ul-images">
                                            <li class="replay-li" v-for="(item__, index) in item_.attachList"
                                                :key="index">
                                                <img :src="OSSURL + item__.filepath" alt=""
                                                    @click="showBigImg(item__.filepath)">
                                            </li>
                                        </ul>
                                        <span class="create-time">{{ item_.submitTime }}</span>
                                    </div>
                                </div>
                                <div class="reply-main">
                                    <div class="reply-bottom">
                                        <div class="btns">
                                            <el-button link v-show="!item_.unfold" @click="switchUnfoldLevelOne(item_)"
                                                class="grey">
                                                <el-icon>
                                                    <ChatLineRound />
                                                </el-icon>
                                                <span>回复</span>
                                            </el-button>
                                            <el-button link v-show="item_.unfold"
                                                @click="switchUnfoldLevelOne(item_)">收起∧</el-button>
                                            <div class="reply-like" @click="likeComment(item_)">
                                                <img src="@/assets/icons/heart-w.png" alt="" srcset=""
                                                    style="width:16px;cursor: pointer;margin-right: 3px;"
                                                    v-show="item_.likeStatus !== 1">
                                                <img src="@/assets/icons/heart-r.png" alt="" srcset=""
                                                    style="width:16px;cursor: pointer;margin-right: 3px;"
                                                    v-show="item_.likeStatus === 1">
                                                <span v-text="item_.likeNum" class="grey"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="comment-editor" v-show="item_.unfold">
                                <Editor :model="item_" @submit="submitComment($event, 3, item)"></Editor>
                            </div>
                        </li>
                        <div class="find-more"
                            v-show="item.totalReply > 3 && item.totalReply !== item.replyList.length">
                            <el-button v-show="item.totalReply > 5" size="small" type="danger"
                                @click="showAllReply(item)">
                                查看全部 {{ item.totalReply }} 条回复
                            </el-button>
                            <el-button v-show="item.totalReply <= 5" size="small" type="danger"
                                @click="expandReply(item)">
                                展开其它 {{ item.totalReply - item.replyList.length }} 条回复
                            </el-button>
                        </div>
                    </ul>
                </div>
            </li>
            <li class="more"
                v-show="!isLoadingCommentList && !commentData.isFinish && commentData.total > commentData.size">
                <el-button link @click="onLoadMoreList">
                    显示更多评论∨
                </el-button>
            </li>
            <li class="desc"
                v-show="!isLoadingCommentList && commentData.isFinish && commentData.total !== 0 && commentData.total < commentData.size">
                已显示全部评论
            </li>
            <li class="desc" v-show="!isLoadingCommentList && commentData.isFinish && commentData.total === 0">
                暂无评论
            </li>
            <li v-show="isLoadingCommentList" class="desc">加载中...</li>
        </ul>
    </div>
    <div class="zoom-img" v-show="isZoom" @click="() => isZoom = false">
        <img :src="OSSURL + currImage">
    </div>
    <el-dialog v-model="dialogAllReply.visible" title="评论回复" width="800">
        <div class="dialog-reply-wrap">
            <div class="reply-t">
                <div class="reply-t-l">
                    <div class="reply-avatar">
                        <img :src="OSSURL + dialogAllReply.author.author.avatar" alt=""
                            @click="goPersonal(dialogAllReply.author.author)" />
                    </div>
                    <div class="reply-content">
                        <div class="top">
                            <span class="nickname" @click="goPersonal(dialogAllReply.author.author)">
                                {{ dialogAllReply.author.author.nickName }}
                            </span>
                        </div>
                        <div class="middle">
                            {{ dialogAllReply.author.content }}
                        </div>
                        <span class="datetime">
                            {{ dialogAllReply.author.submitTime }}
                        </span>
                    </div>
                </div>
                <div class="reply-main">
                    <div class="bottom" @click="likeComment(dialogAllReply.author)">
                        <div class="btns">
                            <img src="@/assets/icons/heart-w.png" alt="" srcset=""
                                style="width:16px;cursor: pointer;margin-right: 3px;"
                                v-show="dialogAllReply.author.likeStatus !== 1">
                            <img src="@/assets/icons/heart-r.png" alt="" srcset=""
                                style="width:16px;cursor: pointer;margin-right: 3px;"
                                v-show="dialogAllReply.author.likeStatus === 1">
                            <span v-text="dialogAllReply.author.likeNum" class="grey"></span>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="reply-ul">
                <li class="title">
                    {{ dialogAllReply.total }} 条回复
                </li>
                <li class="reply-li" v-for="(item, index) in dialogAllReply.list" :key="item.commentId">
                    <div class="reply-li-body">
                        <div class="reply-li-body-l">
                            <div class="reply-li-avatar">
                                <img :src="OSSURL + item.author.avatar" alt="" @click="goPersonal(item.author)" />
                            </div>
                            <div class="reply-li-body-content">
                                <div class="top">
                                    <span class="nickname">
                                        <span class="self" @click="goPersonal(item.author)">{{
                                            item.author.nickName }}</span>
                                    </span>
                                    <span v-if="item.reply && !item.isAuthor" style="color: #dbdbdb;">@{{
                                        item.reply.nickName }}</span>
                                    <span v-else-if="item.isAuthor" style="color: #dbdbdb;">@我</span>
                                </div>
                                <div class="middle">
                                    <div class="content">
                                        {{ item.content }}
                                    </div>
                                    <ul class="images-ul">
                                        <li class="image-li" v-for="(item_, index) in item.attachList" :key="index">
                                            <img :src="OSSURL + item_.filepath" alt=""
                                                @click="showBigImg(item_.filepath)">
                                        </li>
                                    </ul>
                                </div>
                                <span class="datetime">
                                    {{ item.submitTime }}
                                </span>
                            </div>
                        </div>

                        <div class="reply-li-main">
                            <div class="bottom">
                                <div class="btns">
                                    <div class="reply-btn">
                                        <el-button link v-show="!item.unfold" @click="switchUnfoldLevelOne(item)"
                                            class="grey">
                                            <el-icon>
                                                <ChatLineRound />
                                            </el-icon>
                                            <span>回复</span>
                                        </el-button>
                                        <el-button link v-show="item.unfold" @click="switchUnfoldLevelOne(item)"
                                            class="grey">收起∧</el-button>
                                    </div>
                                    <div class="like" @click="likeComment(item, index)">
                                        <img src="@/assets/icons/heart-w.png" alt="" srcset=""
                                            style="width:16px;cursor: pointer;margin-right: 3px;"
                                            v-show="item.likeStatus !== 1">
                                        <img src="@/assets/icons/heart-r.png" alt="" srcset=""
                                            style="width:16px;cursor: pointer;margin-right: 3px;"
                                            v-show="item.likeStatus === 1">
                                        <span v-text="item.likeNum" class="grey"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="editor" v-show="item.unfold">
                        <Editor :model="item" @submit="submitComment($event, 4, dialogAllReply.author)"></Editor>
                    </div>
                </li>
            </ul>
        </div>
    </el-dialog>
</template>

<script setup>
import { isUserLoggedIn } from '@/router'
import { OSSURL } from '@/util/file';
import { commentListApi, addCommentApi, likeCommentApi, expandCommentApi } from '@/api/comment.js'
import Editor from './components/Editor.vue'
import { useStore } from 'vuex'
import { useRouter,useRoute } from 'vue-router';
import { ref, onMounted, reactive, watch } from 'vue'
import messageBox from '@/constant/message';
const route = useRoute();
const router = useRouter()
const store = useStore()
const props = defineProps({
    bizId: Number,
    bizType: Number,
    counterLimit: Number,
    width: {
        type: String,
        default: '100%'
    }
})
const commentData = reactive({
    page: 1,
    size: 10,
    total: 0,
    list: [],
    isFinish: false
})
const dialogAllReply = reactive({
    list: [],
    oldlist: [],
    author: {},
    total: 0,
    visible: false,
    origin: {}
});
const editorModel = reactive({
    pid: 0
})
const isLoadingCommentList = ref(false);
const isZoom = ref(false);
const currImage = ref('');
const lastEditor = ref(null);
async function handleLogin() {
    await store.dispatch('getWeChatSetting');
    store.commit('SET_LOGIN_MODAL_VISIBLE', {
        visible: true,
        type: 'wxLogin',
        path: route.fullPath,
    });
}
async function submitComment(contentData, submitType, father = {}) {
    try {
        let params = {}
        let commentRes = {}
        switch (submitType) {
            //顶部提交评论
            case 1:
                params = {
                    content: contentData.contentHtml,
                    bizId: props.bizId,
                    bizType: props.bizType,
                    pid: contentData.pid,
                    attachList: contentData.images.map((item) => ({ filepath: item }))
                }
                commentRes = await addCommentApi(params)
                commentRes.attachList = commentRes.attachList || []
                commentRes.unfold = false
                commentData.list.unshift({
                    info: { ...commentRes },
                    attachList: params.attachList,
                    replyList: [],
                    totalReply: 0,
                    expandNum: 0,
                    unfold: false
                })
                commentData.total += 1
                break;
            //回复1级评论
            case 2:
                params = {
                    content: contentData.contentHtml,
                    bizId: props.bizId,
                    bizType: props.bizType,
                    pid: contentData.info.commentId,
                    attachList: contentData.images.map((item) => ({ filepath: item }))
                }
                commentRes = await addCommentApi(params)
                commentRes.attachList = commentRes.attachList || []
                commentRes.unfold = false
                contentData.oldItem.totalReply += 1;
                contentData.replyList.unshift(commentRes)
                break;
            case 3:
                params = {
                    content: contentData.contentHtml,
                    bizId: props.bizId,
                    bizType: props.bizType,
                    pid: contentData.commentId,
                    attachList: contentData.images.map((item) => ({ filepath: item }))
                }
                commentRes = await addCommentApi(params)
                commentRes.attachList = commentRes.attachList || []
                commentRes.unfold = false
                father.totalReply += 1;
                father.replyList.unshift(commentRes)
                break;
            case 4:
                params = {
                    content: contentData.contentHtml,
                    bizId: props.bizId,
                    bizType: props.bizType,
                    pid: contentData.commentId,
                    attachList: contentData.images.map((item) => ({ filepath: item }))
                }
                commentRes = await addCommentApi(params)
                commentRes.attachList = commentRes.attachList || []
                commentRes.unfold = false
                dialogAllReply.list.unshift(commentRes)
                dialogAllReply.total += 1
                dialogAllReply.origin.totalReply += 1
                break;
        }
        contentData.oldItem.unfold = false;
        messageBox.success('评论发布成功！');
    } catch (error) {
        console.log(error);
    }
}
async function getCommentList() {
    try {
        isLoadingCommentList.value = true;
        const res = await commentListApi({
            bizId: props.bizId,
            bizType: props.bizType,
            userId: store.state.user.userView.id || 0,
            page: commentData.page,
            size: commentData.size,
            counterLimit: props.counterLimit
        })
        commentData.total = res.commentTotal;
        if (!res.result) {
            isLoadingCommentList.value = false;
            commentData.isFinish = true;
            return;
        }
        res?.result?.forEach(item => {
            item.unfold = false;
            item.clear = false;
            item.replyList = item.replyList || [];
            item.attachList = item.info.attachList || [];
            item.replyList.forEach(item_ => {
                item_.unfold = false;
                item_.clear = false;
                item_.attachList = item_.attachList || [];
            });
        })
        isLoadingCommentList.value = false;
        if (commentData.list.length === 0) return (commentData.list = res.result)
        commentData.list = [...commentData.list, ...res.result];
    } catch (error) {
        console.log(error);
    }
}
async function likeComment(item, index) {
    try {
        if (!isUserLoggedIn()) {
            handleLogin();
            return;
        }
        const commentId = item?.info?.commentId || item?.commentId;
        let likeStatus = item?.info?.likeStatus;//点赞状态0未点赞 1点赞
        if (likeStatus === undefined) {
            likeStatus = item?.likeStatus
        }
        switch (likeStatus) {
            case 0:
            case 2:
                likeStatus = 1
                if (item.info) {
                    item.info.likeNum += 1;
                    item.info.likeStatus = 1;

                } else {
                    item.likeNum += 1;
                    item.likeStatus = 1;
                }
                if (index !== undefined && dialogAllReply.oldlist[index]) {
                    dialogAllReply.oldlist[index].likeNum += 1;
                    dialogAllReply.oldlist[index].likeStatus = 1;
                }
                break;
            case 1:
                likeStatus = 2
                if (item.info) {
                    item.info.likeNum -= 1;
                    item.info.likeStatus = 2;
                } else {
                    item.likeNum -= 1;
                    item.likeStatus = 2;
                }
                if (index !== undefined && dialogAllReply.oldlist[index]) {
                    dialogAllReply.oldlist[index].likeNum -= 1;
                    dialogAllReply.oldlist[index].likeStatus = 2;
                }
                break;
        }

        await likeCommentApi({ commentId, status: likeStatus });
    } catch (error) {
        console.log(error);
    }
}
async function expandReply(params) {
    try {
        const res = await expandCommentApi({ commentId: params.info.commentId, userId: store.state.user.userView.id });
        res.result.forEach(item => {
            item.unfold = false;
            item.attachList = item.attachList || [];
        });
        params.replyList = res.result;
    } catch (error) {
        console.log(error);
    }
}
async function showAllReply(params) {
    try {
        if (lastEditor.value) {
            lastEditor.value.unfold = false;
            lastEditor.value = null;
        }
        const res = await expandCommentApi({ commentId: params.info.commentId, userId: store.state.user.userView.id });
        res.result.forEach(item => {
            item.unfold = false;
            item.attachList = item.attachList || [];
        });
        dialogAllReply.total = params.totalReply;
        dialogAllReply.author = params.info;
        dialogAllReply.list = res.result;
        dialogAllReply.oldlist = params.replyList;
        dialogAllReply.origin = params;
        dialogAllReply.visible = true;
    } catch (error) {
        console.log(error);
    }
}
function showBigImg(url) {
    isZoom.value = true;
    currImage.value = url;
}
function onLoadMoreList() {
    commentData.page += 1;
    getCommentList();
}
function goPersonal(userInfo) {
    const url_ = router.resolve({ path: '/personPage', query: { id: userInfo.userId } })
    window.open(url_.href, '_blank')
}
//外层评论框切换
function switchUnfoldLevelOne(item) {
    if (item === lastEditor.value && item.unfold) {
        item.unfold = false
        lastEditor.value = null
        item.clear = true
        return;
    }
    if (lastEditor.value) {
        lastEditor.value.unfold = false
        lastEditor.value.clear = true;
    }
    item.unfold = !item.unfold
    if (item.unfold) {
        lastEditor.value = item
    }
}
function handlercommentMain(width) {
    if (width.endsWith('%')) {
        return {
            width: 'calc(100% - 55px)'
        }
    }
    return {
        width: width.slice(0, -2) - 55 + 'px'
    }
}

watch(() => props.bizId, () => {
    commentData.list = [];
    getCommentList();
})

onMounted(() => {
    getCommentList();
})
</script>

<style lang="scss" scoped>
.create-time,
.datetime {
    font-size: 12px;
}

.grey {
    color: #dbdbdb !important;
}

.comment-wrap {
    color: #fff;

    .comments-ul {
        padding: 24px 0;

        >.comment-li {
            margin-bottom: 24px;
            display: flex;

            >.comment-avator {
                cursor: pointer;
                width: 35px;
                margin-right: 20px;

                >img {
                    width: 35px;
                    border-radius: 50%;
                }
            }

            .comment-main {

                >.auhtor-info {
                    margin-bottom: 10px;

                    >.author-name {
                        cursor: pointer;
                        margin-right: 20px;
                    }
                }

                >.content {
                    margin-bottom: 10px;
                }

                >.img-list {
                    margin-bottom: 10px;
                    display: flex;
                    flex-wrap: wrap;

                    >li {
                        margin-right: 10px;

                        >img {
                            cursor: pointer;
                            width: 50px;
                            height: 50px;
                        }
                    }
                }

                >.content-bottom {
                    margin-bottom: 20px;
                    display: flex;
                    justify-content: space-between;



                    >.btns {
                        display: flex;
                        align-items: center;

                        >.like {
                            display: flex;
                            align-items: center;
                            font-size: 12px;
                            margin-left: 16px;
                        }
                    }
                }

                >.reply-ul {
                    >.reply-li {
                        >.reply-li-main {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 16px;

                            >.reply-li-l {
                                display: flex;

                                >.reply-avatar {
                                    margin-right: 10px;

                                    >img {
                                        cursor: pointer;
                                        width: 30px;
                                        border-radius: 50%;
                                    }
                                }

                                >.main-top {

                                    >.reply-auhtor-info {
                                        margin-bottom: 6px;

                                        >.author-name {

                                            >.self {
                                                margin-right: 5px;
                                                cursor: pointer;
                                            }
                                        }
                                    }

                                    >.reply-content {
                                        margin-bottom: 10px;
                                    }

                                    >.reply-ul-images {
                                        margin-bottom: 10px;
                                        display: flex;
                                        flex-wrap: wrap;

                                        >.replay-li {
                                            margin-right: 10px;

                                            >img {
                                                cursor: pointer;
                                                width: 50px;
                                                height: 50px;
                                            }
                                        }
                                    }

                                }

                            }

                            >.reply-main {
                                display: flex;
                                align-items: flex-end;

                                >.reply-bottom {
                                    font-size: 12px;
                                    display: flex;



                                    >.btns {
                                        display: flex;

                                        >.reply-like {
                                            font-size: 14px;
                                            margin-left: 16px;
                                            display: flex;
                                            align-items: center;
                                        }
                                    }
                                }
                            }
                        }


                    }

                    >.find-more {
                        padding-left: 30px;
                        padding-bottom: 20px;
                    }
                }
            }
        }

        >.more {
            display: flex;
            justify-content: center;
        }

        >.desc {
            display: flex;
            justify-content: center;
        }
    }
}

.dialog-reply-wrap {
    color: #fff;
    font-size: 16px;

    >.reply-t {
        display: flex;
        box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
        padding: 10px;
        margin-bottom: 20px;
        justify-content: space-between;

        .reply-t-l {
            display: flex;

            >.reply-avatar {
                width: 50px;
                margin-right: 20px;

                >img {
                    cursor: pointer;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                }
            }

            >.reply-content {
                >.top {
                    margin-bottom: 10px;

                    >.nickname {
                        cursor: pointer;
                    }
                }

                >.middle {
                    margin-bottom: 10px;
                }
            }
        }

        >.reply-main {
            display: flex;
            align-items: flex-end;



            >.bottom {
                display: flex;
                justify-content: space-between;

                >.btns {
                    display: flex;
                    align-items: center;
                }

            }
        }
    }

    >.reply-ul {
        padding: 10px;
        box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
        max-height: 650px;
        overflow: auto;

        >.title {
            font-size: 25px;
            font-weight: 700;
            margin-bottom: 30px;
        }

        >.reply-li {
            margin-bottom: 20px;

            >.reply-li-body {
                display: flex;
                justify-content: space-between;

                >.reply-li-body-l {
                    display: flex;

                    >.reply-li-avatar {
                        width: 50px;
                        margin-right: 20px;

                        >img {
                            cursor: pointer;
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                        }
                    }

                    >.reply-li-body-content {
                        >.top {
                            margin-bottom: 10px;

                            >.nickname {

                                >.self {
                                    margin-right: 5px;
                                    cursor: pointer;
                                }
                            }
                        }

                        >.middle {
                            margin-bottom: 10px;

                            >.content {
                                margin-bottom: 10px;
                            }

                            >.images-ul {
                                display: flex;
                                flex-wrap: wrap;

                                >.image-li {
                                    margin-right: 10px;

                                    >img {
                                        cursor: pointer;
                                        width: 50px;
                                        height: 50px;
                                    }
                                }
                            }
                        }
                    }
                }


                >.reply-li-main {
                    display: flex;
                    align-items: flex-end;

                    >.bottom {
                        font-size: 14px;

                        >.btns {
                            display: flex;
                            align-items: center;

                            >.like {
                                margin-left: 16px;
                                display: flex;
                                align-items: center;
                            }
                        }
                    }
                }
            }

        }
    }
}

.zoom-img {
    z-index: 9999;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);

    img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
}

.el-button+.el-button {
    margin-left: 0;
}
</style>