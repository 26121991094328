import {postAction, getAction} from './manage';
const genV1ApiUrl = (path) => {
    return `v1/api/${path}`
}

// 素材列表
export const myCollectionList = (params) => {
    return postAction(genV1ApiUrl('collect/page-list'), params)
}

// 取消收藏素材
export const cancelCollection = (params) => {
    return postAction(genV1ApiUrl('collect/batch/del'), params)
}